import { Button, Grid, Typography } from "@mui/material";
import Overlay from "../components/Overlay";
import CloseIcon from '@mui/icons-material/Close';
import TutorialBox from "../views/TutorialBox";

export default function ReportOverlay({ type, open, onClose, onReport, highlighted_report = false }) {
    return (
        <Overlay className="overlay" open={open} showClose={false} onClose={onClose} title="SEGNALA UN PROBLEMA">
            <Grid item marginBottom="2em">
                Clicca il pulsante "<strong>Segnala un problema</strong>" per confermare di aver riscontrato uno dei seguenti problemi:
                <ul>
                    <li>le opzioni di risposta da visualizzare non sono coerenti con la domanda;</li>
                    <li>l'opzione selezionata e la risposta mostrata nella schermata di conferma e/o riepilogo non corrispondono;</li>
                    <li>non è presente il risultato corretto per la somma tra le opzioni disponibili.</li>
                    <li>il meccanismo di sicurezza ha rilevato un attacco informatico: il set di { type === 'emojis' ? 'emoji' : 'vocaboli' } mostrato in alto a sinistra dopo la preferenza e/o quello in alto a destra dopo la somma è cambiato nel corso di uno stesso livello.</li>
                </ul>
                La segnalazione concluderà il livello in corso, tornando alla schermata di progresso. <br />
            </Grid>

            <Grid container 
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                <Grid item>
                    <Button variant="outlined" onClick={onClose}>Annulla</Button>
                </Grid>
                <Grid item>
                    <TutorialBox enable={highlighted_report}>
                        <Button variant="contained" onClick={onReport}>Segnala un problema</Button>
                    </TutorialBox>
                </Grid>
            </Grid>
        </Overlay>
    )
}