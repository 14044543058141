import { useContext } from 'react';
import configs from '../Config';
import { defaultContext } from '../App';


function fetchAPI(url, options={}, json=null) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(options.hasOwnProperty('headers'))
        options['headers']['X-USR-CODE'] = urlParams.get('test_id')
    else
        options['headers'] = { 'X-USR-CODE': urlParams.get('test_id') }

    if(json !== null) {
        options['headers']['Content-Type'] = 'application/json'
        options['method'] = 'POST'
        options['body'] = JSON.stringify(json)
    }
    return fetch(configs.url + url, options)
        .then(r => {
            if(r.status === 500)
                console.log("ERRORE")
            if(r.status === 499)
                console.log("INCONSISTENT")
            return r.json()
        })
}

export default fetchAPI;
