import { useEffect } from "react";
import Info from "../components/Info";
import fetchAPI from "../utils/Fetcher"

export default function Info1({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/1/show") })

    return (
        <Info enableLeft={false} enableRight={true} enabledContinue={false} 
            num={1}
            clickLeft={() => {}}
            clickRight={() => setQuestionStatus('info_2')}
            clickContinue={() => {}}>
            <p>
                Il test si compone di 6 livelli obbligatori, seguiti da livelli bonus opzionali (fino a 10 livelli). <br />
                Per ogni livello ti sarà chiesto rispondere a dei semplici quesiti. <br />
                Completando un livello otterrai dei punti, e il totale alla fine del test darà la tua posizione in una classifica anonimizzata.
            </p>
        </Info>
    )
}