import Info1 from "./Info1";
import Info2 from "./Info2";
import Info3 from "./Info3";
import Info4 from "./Info4";
import Info5 from "./Info5";
import Info6 from "./Info6";
import Info7 from "./Info7";
import Info8 from "./Info8";
import Info9 from "./Info9";

export default function InfoManager({ type, questionStatus, setQuestionStatus }) {
    return (
        <>
            { questionStatus === 'info_1' && <Info1 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_2' && <Info2 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_3' && <Info3 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_4' && <Info4 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_5' && <Info5 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_6' && <Info6 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_7' && <Info7 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_8' && <Info8 type={type} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'info_9' && <Info9 type={type} setQuestionStatus={setQuestionStatus} /> }
        </>
    )
}