import { useEffect } from "react";
import Info from "../components/Info";
import fetchAPI from "../utils/Fetcher"

export default function Info5({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/5/show") })

    return (
        <Info enableLeft={true} enableRight={true} enabledContinue={false} 
            num={5}
            clickLeft={() => setQuestionStatus('info_4')}
            clickRight={() => setQuestionStatus('info_6')}
            clickContinue={() => {}}>
            <p>
                <strong>Nota bene:</strong><br />
                Dopo aver risposto a ciascuna domanda, una volta cliccato "Prosegui", visualizzerai una schermata di conferma della tua scelta.
                A quel punto, non sarà possibile modificare la tua risposta. <br />
                Al termine di ogni livello, ti verrà mostrata una schermata di riepilogo delle risposte date.
            </p>
        </Info>
    )
}