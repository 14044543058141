import { Grid } from "@mui/material";
import Dots from "../components/Dots";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import QuestionView from "../views/QuestionView";

export default function Awareness({ type, setIntroStatus }) {
    return (
        <QuestionView
            enable_encoding={false}
            question="Mentre stai svolgendo un'attività, quanto sei consapevole di ciò che accade intorno a te?"
            question_type="text"
            answares={[
                { value: "Per nulla", answare: "Per nulla" },
                { value: "Poco", answare: "Poco" },
                { value: "Abbastanza", answare: "Abbastanza" },
                { value: "Molto", answare: "Molto" },
                { value: "Moltissimo", answare: "Moltissimo" },
            ]}

            enable_report={false}
            enable_info={false}

            enable_steps={true}
            steps_current={6}
            steps_total={7}

            onSubmit={ans => {
                fetchAPI('/intro/question/awareness/submit', {}, {"answare": ans})
                    .then(() => {
                        if(type === 'emojis') {
                            setIntroStatus('emojis')
                        } else {
                            setIntroStatus('finished')
                        }
                    })
            }}
            />



        // <>
        //     <TextQuestion question="Mentre stai svolgendo un'attività, quanto sei consapevole di ciò che accade intorno a te?" 
        //             answares={[
        //                 "Per nulla", 
        //                 "Poco", 
        //                 "Abbastanza", 
        //                 "Molto", 
        //                 "Moltissimo" 
        //             ]} setAnsware={ans => {
        //                 fetchAPI('/intro/question/awareness/submit', {}, {"answare": ans})
        //                     .then(() => {
        //                         if(type === 'emojis') {
        //                             setIntroStatus('emojis')
        //                         } else {
        //                             setIntroStatus('finished')
        //                         }
        //                     })
        //             }} />

        //     <Grid item>
        //         <Dots index={6}
        //             total={7} />
        //     </Grid>
        // </>
    );
}