import { Grid } from "@mui/material";
import Dots from "../components/Dots";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import QuestionView from "../views/QuestionView";

export default function Age({ setIntroStatus }) {
    return (
        <QuestionView 
            enable_encoding={false}
            question="Quale delle categorie indicate include la tua età?"
            question_type="text"
            answares={[
                { value: "Meno di 20", answare: "Meno di 20" },
                { value: "Tra 20 e 34", answare: "Tra 20 e 34" },
                { value: "Tra 35 e 49", answare: "Tra 35 e 49" },
                { value: "Tra 50 e 64", answare: "Tra 50 e 64" },
                { value: "Tra 65 e 79", answare: "Tra 65 e 79" },
                { value: "80 o più", answare: "80 o più" },
            ]}

            enable_report={false}
            enable_info={false}

            enable_steps={true}
            steps_current={1}
            steps_total={7}

            onSubmit={ans => {
                fetchAPI('/intro/question/age/submit', {}, {"answare": ans})
                    .then(() => setIntroStatus('dimension'))
            }}

            />
        // <>
            

        //     {/* <Grid item />

        //     {/* <Grid item> */}
        //         <TextQuestion question="Quale delle categorie indicate include la tua età?" 
        //                 answares={[
        //                     "Meno di 20", 
        //                     "Tra 20 e 34",
        //                     "Tra 35 e 49",
        //                     "Tra 50 e 64",
        //                     "Tra 65 e 79",
        //                     "80 o più"
        //                 ]} setAnsware={ans => {
        //                     fetchAPI('/intro/question/age/submit', {}, {"answare": ans})
        //                         .then(() => setIntroStatus('dimension'))
        //                 }} />
        //     {/* </Grid> */}

        //     <Grid item>
        //         <Dots index={1}
        //             total={7} />
        //     </Grid> */}
        // </>
    );
}