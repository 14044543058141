import { Button, Grid, Paper } from "@mui/material";

export default function TutorialInfoOverlay({ children, showed, onClickOk }) {
    if(!showed) {
        return <></>
    }

    return (
        <Paper elevation={7} className="tutorial_overlay"
                style={{ backgroundColor: "#E5FFEA" }}>
            <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                <Grid item>
                    <span className="tutorial_overlay_content">
                        { children }
                    </span>
                </Grid>

                <Grid item>
                    <Button variant="contained" onClick={onClickOk}>OK</Button>
                </Grid>
            </Grid>
        </Paper>
    )
}
