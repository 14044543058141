import { useState } from "react";
import QuestionSubView from "./QuestionSubViews";
import LevelView from "./LevelView";

export default function QuestionView({
    // encoding
    enable_encoding = true,
    encoding_type = "none", 
    encoding_content = null, 
    enable_left_highlighted = false, 
    enable_right_highlighted = false,

    // tutorial
    enable_tutorial_button = false, 
    enable_tutorial_overlay = false, 
    tutorial_overlay_content = null,
    onOverlayOk = () => {},

    // question
    test_type=null, 
    question, 
    question_type, 
    answares,
    highlight_question = false,

    // report
    enable_report = true, 
    highlighted_report = false, 
    onReport = () => {}, 
    onReportState = () => {}, 
    allow_report = true,

    // submit
    enable_submit = true,
    highlighted_submit = false,
    submit_text = "PROSEGUI",
    onSubmit = _ => {},
    allow_submit = null,
    onSelectionChange = _ => {},

    selected = null,

    // info
    enable_info = true,
    highlighted_info = false,
    onInfoState = () => {},
    allow_info = true, 

    // steps
    enable_steps = false,
    steps_current = 0,
    steps_total = 1,

    default_state = 0,
}) {
    const [subSelected, setSubSelected] = useState(selected)

    return (
        <LevelView 
                enable_encoding={enable_encoding}
                encoding_type={encoding_type}
                encoding_content={encoding_content}
                enable_left_highlighted={enable_left_highlighted}
                enable_right_highlighted={enable_right_highlighted}

                enable_tutorial_button={enable_tutorial_button}
                enable_tutorial_overlay={enable_tutorial_overlay}
                tutorial_overlay_content={tutorial_overlay_content}
                onOverlayOk={onOverlayOk}

                type={test_type}
                allow_info={allow_info}

                enable_report={enable_report}
                highlighted_report={highlighted_report}
                onReport={onReport}
                onReportState={onReportState}
                allow_report={allow_report}

                enable_submit={enable_submit}
                highlighted_submit={highlighted_submit}
                submit_text={submit_text}
                onSubmit={() => onSubmit(subSelected)}
                allow_submit={allow_submit != null ? allow_submit : subSelected != null}

                enable_info={enable_info}
                highlighted_info={highlighted_info}
                onInfoState={onInfoState}

                enable_steps={enable_steps}
                steps_current={steps_current}
                steps_total={steps_total}
                
                default_state={default_state}>

            <QuestionSubView type={question_type}
                    question={question} answares={answares}
                    highligh_question={highlight_question}
                    selected={subSelected} onClick={e => {
                        onSelectionChange(e)
                        setSubSelected(e)}
                    } />
        </LevelView>
    )
}