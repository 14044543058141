import { Typography } from "@mui/material";

export default function Finished() {
    return (
        <>
        <Typography variant="h2" className="mainTitle">GRAZIE</Typography>

        <p>
            Hai completato il test. <br />
            Grazie mille per averci aiutato.
        </p>
        <p></p>
        </>
    )
}
