import { Grid } from "@mui/material";
import Dots from "../components/Dots";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import QuestionView from "../views/QuestionView";

export default function Attention({ setIntroStatus }) {
    return (
        <QuestionView
            enable_encoding={false}
            question="Quando svolgi un'attività che richiede concentrazione, quanto spesso riesci a mantenere la concentrazione senza lasciarti distrarre da fattori esterni?"
            question_type="text"
            answares={[
                { value: "Mai", answare: "Mai" },
                { value: "Raramente", answare: "Raramente" },
                { value: "A volte", answare: "A volte" },
                { value: "Spesso", answare: "Spesso" },
                { value: "Sempre", answare: "Sempre" },
            ]}

            enable_report={false}
            enable_info={false}

            enable_steps={true}
            steps_current={4}
            steps_total={7}

            onSubmit={ans => {
                fetchAPI('/intro/question/attention/submit', {}, {"answare": ans})
                    .then(() => setIntroStatus('details'))
            }}

            />



        // <>
        //     <TextQuestion question="Quando svolgi un'attività che richiede concentrazione, quanto spesso riesci a mantenere la concentrazione senza lasciarti distrarre da fattori esterni?" 
        //             answares={[
        //                 "Mai", 
        //                 "Raramente", 
        //                 "A volte", 
        //                 "Spesso", 
        //                 "Sempre" 
        //             ]} setAnsware={ans => {
        //                 fetchAPI('/intro/question/attention/submit', {}, {"answare": ans})
        //                     .then(() => setIntroStatus('details'))
        //             }} />

        //     <Grid item>
        //         <Dots index={4}
        //             total={7} />
        //     </Grid>
        // </>
    );
}