import { Box, Radio } from "@mui/material"

export default function TutorialBox({ enable, children, radio=false }) {
    if(radio) {
        if(enable === false) {
            return <Radio />
        } else {
        return <Radio sx={{ paddingLeft: "0.3em", paddingRight: "0.3em",  paddingTop: "0.1em", paddingBottom: '0.1em',
            border: "solid 5px " + enable }} />
        }
    }

    if(enable === undefined || enable === false) {
        return children
    }

    return (
        <Box component="div" sx={{ paddingLeft: "0.3em", paddingRight: "0.3em",  paddingTop: "0.1em", paddingBottom: '0.1em',
                    border: "solid 5px " + enable }}>
            { children }
        </Box>
    )
}