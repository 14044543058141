import { useState } from "react";
import Coherence from "./Coherence";
import Answare from "./Answare";
import Emoji from "./Emoji";
import Missing from "./Missing";
import Reported from "./Reported";
import Reason from "./Reason";

export default function Survey({ type, initSurveyStatus, setSection }) {
    if(initSurveyStatus === null || initSurveyStatus === 'not_started')
        initSurveyStatus = 'coerence'
    const [surveyStatus, setSurveyStatus] = useState(initSurveyStatus)
    if(surveyStatus === 'finished')
        setSection('finished')

    return (
        <>
            { surveyStatus === 'coerence' && <Coherence setSurveyStatus={setSurveyStatus} /> }
            { surveyStatus === 'answare' && <Answare setSurveyStatus={setSurveyStatus} /> }
            { surveyStatus === 'emoji' && <Emoji type={type} setSurveyStatus={setSurveyStatus} /> }
            { surveyStatus === 'missing' && <Missing setSurveyStatus={setSurveyStatus} /> }
            { surveyStatus === 'reported' && <Reported setSurveyStatus={setSurveyStatus} /> }
            { surveyStatus === 'reason' && <Reason setSurveyStatus={setSurveyStatus} /> }
        </>
    )
}