import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';

export default function FurtherInformation( {onClick} ) {
    return (
        <Grid container justifyContent="center" 
            alignItems="center"
            style={{margin: "0px"}}>
            <Grid item>
                <SearchIcon className='fakeLink' onClick={onClick} />
            </Grid>
            <Grid item>
                <span className='fakeLink' onClick={onClick}>Maggiori informazioni</span>
            </Grid>
        </Grid>
    )
}