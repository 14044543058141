import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"
import configs from "../Config"
import TutorialBox from "./TutorialBox"


function TextQuestion({ data, onClick, selected }) {
    return (
        <FormControlLabel value={data.value}
                          checked={selected == data.value || data.highlighted === true}
                          control={
                                data.highlighted === false ? <Radio /> :
                                <Radio style={{ padding: "0.2em", 
                                    border: "solid 5px " + data.highlighted }} />
                          }
                          label={<p className="answare" style={{ textAlign: 'left' }}>{ data.answare }</p>}
                          onClick={() => onClick(data.value)} />
    )
}

function ImgQuestion({ data, onClick, selected }) {
    return (
        <>
            <FormControlLabel value={data.value}
                            checked={selected == data.value || data.highlighted === true}
                            control={
                                data.highlighted === false ? <Radio /> :
                                <Radio style={{ padding: "0.2em", 
                                    border: "solid 5px " + data.highlighted }} />
                            }
                            label={<img src={configs.url + "/static/img/" + data.path} /> }
                            onClick={() => onClick(data.value)} />
            <p className="answare">{data.answare}</p>
        </>
    )
}

export default function QuestionSubView({ type, question, answares, onClick, selected, highligh_question }) {
    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center">

            <Grid item className="questionTitle" marginBottom="1em">
                <TutorialBox enable={highligh_question}>
                    { question }
                </TutorialBox>
            </Grid>

            <Grid item>
                <FormControl>
                    <RadioGroup>
                        { answares.map(e => 
                            type === 'text' ?
                            <TextQuestion data={e} onClick={onClick} selected={selected} /> :
                            <ImgQuestion data={e} onClick={onClick} selected={selected} />) 
                        }
                    </RadioGroup>
                </FormControl>
            </Grid>

        </Grid>
    )
}