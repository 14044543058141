import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useState } from "react";

export default function CustomDimensionQuesiton({ question, answare, setAnsware }) {
    const [ans, setAns] = useState(null);
    const dims = [4, 8, 12, 15, 18, 21, 25];

    return (
        <>
            <Grid item>
                <div className="questionTitle">{ question }</div>
            </Grid>


            <Grid item>
                <FormControl>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
                        {
                            dims.map(d =>
                                <FormControlLabel value={d} control={<Radio />} 
                                    label={<Typography><span style={{fontSize: d+'pt', textAlign: 'left'}}>{answare}</span></Typography>} 
                                    onClick={() => setAns(d)} />
                            )
                        }
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item>
                <Button variant="contained" disabled={ans === null} onClick={() => setAnsware(ans)}>PROSEGUI</Button>
            </Grid>
        </>
    );
}