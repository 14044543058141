import { Button, Grid } from "@mui/material";
import Dots from "../components/Dots";
import TutorialBox from "./TutorialBox";

export default function ButtonsViews({ 
    enable_report, highlight_report, onReport, allow_report,
    enable_submit, highlight_submit, submit_text, onSubmit, allow_submit,
    enable_info, highlight_info, onInfo,
    enable_steps, steps_current, steps_total,
    allow_info,

    // survey
    enable_survey,
    highlight_survey,
    onSurvey,
    allow_survey,
 }) {

    return (
        <Grid   container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
            <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignContent="center"
                    spacing={1}>
                
                { enable_report &&
                    <TutorialBox enable={highlight_report}>
                        <Grid item onClick={!allow_report ? (() => alert("Per ora non c’è un attacco in corso da segnalare, per favore segui le istruzioni del tutorial.")) : (() => {})}>
                            <Button variant="outlined" disabled={!allow_report}
                                    onClick={allow_report && onReport}>
                                Segnala un problema
                            </Button>
                        </Grid>
                    </TutorialBox>
                }

                { enable_survey &&
                    <TutorialBox enable={highlight_survey}>
                        <Grid item>
                            <Button variant="outlined" disabled={!allow_survey}
                                    onClick={onSurvey}>
                                TERMINA E VAI AL SONDAGGIO
                            </Button>
                        </Grid>
                    </TutorialBox>
                }
                
                { enable_submit &&
                    <TutorialBox enable={highlight_submit}>
                        <Grid item>
                            <Button variant="contained" disabled={!allow_submit}
                                    onClick={onSubmit}>
                                { submit_text }
                            </Button>
                        </Grid>
                    </TutorialBox>
                }
            </Grid>

        { enable_steps &&
            <Grid item>
                <Dots index={steps_current} total={steps_total} />
            </Grid>
        }

        { enable_info && 
            <TutorialBox enable={highlight_info}>
                <Grid item>
                    <span className='fakeLink' onClick={allow_info ? onInfo : () => alert("Pulsante temporaneamente disabilitato, per favore segui le istruzioni del tutorial.")}>
                        { !allow_info ? <span style={{ color: "gray" }}>Maggiori informazioni</span> : <span>Maggiori informazioni</span>}
                    </span>
                </Grid>
            </TutorialBox>
        }
       
    </Grid>
    )
}