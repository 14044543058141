import { useEffect } from "react";
import Info from "../components/Info";
import fetchAPI from "../utils/Fetcher"

export default function Info2({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/2/show") })

    return (
        <Info enableLeft={true} enableRight={true} enabledContinue={false} 
            num={2}
            clickLeft={() => setQuestionStatus('info_1')}
            clickRight={() => setQuestionStatus('info_3')}
            clickContinue={() => {}}>
            <p>
                <strong>Livelli 1 e 2 (obbligatori)</strong><br />
                Ogni livello contiene una singola domanda. <br />
                Dovrai indicare la tua preferenza tra le opzioni di risposta disponibili.
            </p>
        </Info>
    )
}