import { useEffect, useState } from "react"
import fetchAPI from "../utils/Fetcher"
import TextQuestion from "../components/TextQuestion"
import { Button, Grid, Typography } from "@mui/material"
import FurtherInformation from "../components/FurtherInformation"
import LevelInfoOverlay from "./LevelInfoOverlay"
import ReportOverlay from "./ReportOverlay"
import ImgQuestion from "../components/ImgQuestion"
import EncodingRow from "./EncodingRow"
import DemoQuestion from "../views/DemoQuestion"
import QuestionView from "../views/QuestionView"
import SummaryView from "../views/SummaryView"

export default function Question({ testType, level, question, setQuestionStatus, setLevel }) {
    const [data, setData] = useState(null)

    useEffect(() => {
        fetchAPI(`/questions/level/${level}/${question}/show`)
            .then(j => setData(j))
    }, [level, question])

    if(data === null)
        return <p>Caricamento...</p>

    console.log(data)

    function mapAnsware(a, type) {
        let ret = {
            value: a.text,
            answare: a.text
        }
        if(type != 'text') {
            ret.path = a.path
        }
        return ret
    }
    


    return <QuestionView 
                question_type={data.question_type == 'texts' ? 'text' : 'image'}

                encoding_type={testType}
                encoding_content={{
                    left: data.topleft,
                    right: data.topright
                }}

                question={data.question}
                answares={ data.answares.map(a => mapAnsware(a, data.question_type)) }

                onSubmit={a => fetchAPI(`/questions/level/${level}/${question}/submit`, {}, {'answare': a})
                                    .then(() => { setQuestionStatus('confirmation') })}
                
                onInfoState={s => fetchAPI(`/questions/level/${level}/${question}/` + (s ? "info" : "close_info"))}
                onReportState={s => fetchAPI(`/questions/level/${level}/${question}/` + (s ? "report" : "close_report"))}

                onReport={() => {
                    fetchAPI(`/questions/level/${level}/${question}/submit_report`)
                        .then(j => {
                            if(j.has_next_level) {
                                setLevel(l => l + 1)
                            } else {
                                setLevel(null)
                            }
                            setQuestionStatus('level_info')
                        })
                }}/>


    // const [data, setData] = useState(null)
    // const [overlay, setOverlay] = useState(false)
    // const [report, setReport] = useState(false)

    // useEffect(() => {
    //     fetchAPI(`/questions/level/${level}/${question}/show`)
    //         .then(j => setData(j))
    // }, [level, question])

    // if(data === null)
    //     return <p>Caricamento...</p>

    // return (
    //     <SummaryView 
    //         question_type="text"
    //         summary_type="confirmation"
    //         answares={["a", "b"]} />

        // <QuestionView question={"Hello?"} 
        //     answares={[ {value: "ciao", answare: "ciao", highlighted: "red"}, {value: "hello", answare: "hello"} ]} 
        //     question_type={"text"} />










        // // <>
        //     <EncodingRow testType={testType} data={data} />

        //     {
        //         data.question_type === 'texts' ? 
        //         <TextQuestion question={data.question} answares={data.answares.map(a => a.text)} setAnsware={a => {
        //             fetchAPI(`/questions/level/${level}/${question}/submit`, {}, {'answare': a})
        //                 .then(() => { setQuestionStatus('confirmation') })
        //         }}>
        //             <Button variant="outlined" onClick={() => {
        //                 fetchAPI(`/questions/level/${level}/${question}/report`)
        //                     .then(() => setReport(true))
        //             }}>Segnala un problema</Button>
        //         </TextQuestion> :
        //         <ImgQuestion question={data.question} answares={data.answares} setAnsware={a => {
        //             fetchAPI(`/questions/level/${level}/${question}/submit`, {}, {'answare': a})
        //                 .then(() => { setQuestionStatus('confirmation') })
        //         }}>
        //             <Button variant="outlined" onClick={() => {
        //                 fetchAPI(`/questions/level/${level}/${question}/report`)
        //                     .then(() => setReport(true))
        //             }}>Segnala un problema</Button>
        //         </ImgQuestion>
        //     }
            
        //     <Grid item>
        //         <FurtherInformation onClick={() => 
        //             fetchAPI(`/questions/level/${level}/${question}/info`)
        //                 .then(() => setOverlay(true)) } />
        //     </Grid>

        //     <LevelInfoOverlay type={testType} open={overlay} onClose={() => fetchAPI(`/questions/level/${level}/${question}/close_info`)
        //                 .then(() => setOverlay(false))} />

        //     <ReportOverlay type={testType} open={report} onClose={() => {
        //         fetchAPI(`/questions/level/${level}/${question}/close_report`)
        //             .then(() => setReport(false))
        //     }} onReport={() => {
        //         fetchAPI(`/questions/level/${level}/${question}/submit_report`)
        //             .then(j => {
        //                 if(j.has_next_level) {
        //                     setLevel(l => l + 1)
        //                 } else {
        //                     setLevel(null)
        //                 }
        //                 setQuestionStatus('level_info')
        //             })
        //     }} />
        // </>
    // )
}