import { Grid } from "@mui/material";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import Dots from "../components/Dots";
import QuestionView from "../views/QuestionView";

export default function Reported({ setSurveyStatus }) {
    return <QuestionView
                    enable_encoding={false}

                    question_type="text"
                    question="Hai segnalato i problemi riscontrati?"

                    answares={[
                        { value: 'Non ho riscontrato problemi/errori', answare: 'Non ho riscontrato problemi/errori'},
                        { value: 'Sì, sempre', answare: 'Sì, sempre'},
                        { value: 'Sì, a volte', answare: 'Sì, a volte'},
                        { value: 'No, mai', answare: 'No, mai'},
                    ]}

                    enable_report={false}
                    enable_info={false}

                    enable_steps={true}
                    steps_current={5}
                    steps_total={6}

                    onSubmit={a => {
                        fetchAPI('/survey/question/reported/submit', {}, { 'answare': a })
                            .then(() => {
                                if(a === 'Sì, a volte' || a === 'No, mai')
                                    setSurveyStatus('reason')
                                else
                                    setSurveyStatus('finished')
                            })
                    }}

                    />



    // return (
    //     <>
    //         <TextQuestion question="Hai segnalato i problemi riscontrati?"
    //                 answares={[
    //                     'Non ho riscontrato problemi/errori',
    //                     'Sì, sempre',
    //                     'Sì, a volte',
    //                     'No, mai'
    //                 ]}
    //                 setAnsware={a => {
    //                     fetchAPI('/survey/question/reported/submit', {}, { 'answare': a })
    //                         .then(() => {
    //                             if(a === 'Sì, a volte' || a === 'No, mai')
    //                                 setSurveyStatus('reason')
    //                             else
    //                                 setSurveyStatus('finished')
    //                         })
    //                 }} />

    //         <Grid item>
    //             <Dots index={5}
    //                 total={6} />
    //         </Grid>
    //     </>
    // )
}