import Overlay from "../components/Overlay";

export default function LevelInfoOverlay({ type, open, onClose }) {
    return (
        <Overlay className="overlay" open={open} showClose={true} onClose={onClose} title="MAGGIORI INFORMAZIONI">
            Il test si compone di 6 livelli obbligatori, seguiti da livelli bonus opzionali. <br />
            Per ogni livello ti sarà richiesto di rispondere a dei semplici quesiti <br /> <br />

            <strong>Livelli obbligatori:</strong>
            <ul>
                <li>
                    <strong>Livello 1 e 2:</strong>
                    <ul>
                        <li>Ogni livello si compone di una singola domanda.</li>
                        <li>Dovrai indicare la tua preferenza tra le opzioni di risposta disponibili.</li>
                    </ul>
                </li>
                <li>
                    <strong>Livello 3 e 4:</strong>
                    <ul>
                        <li>Ogni livello contiene due domande.</li>
                        <li>La prima domanda ti richiederà di indicare la tua preferenza tra le opzioni disponibili.</li>
                        <li>La seconda domanda ti richiederà di risolvere una somma ad una cifra e selezionare il risultato corretto tra le opzioni disponibili.</li>
                    </ul>
                </li>
                <li>
                    <strong>Livello 5 e 6:</strong>
                    <ul>
                        <li>Ogni livello contiene due domande.</li>
                        <li>Come nei livelli 3 e 4, nella prima domanda dovrai esprimere la tua preferenza tra le opzioni disponibili.</li>
                        <li>Nella seconda domanda, dovrai risolvere una somma a due cifre e scegliere il risultato corretto tra le opzioni disponibili.</li>
                    </ul>
                </li>
            </ul>
            <strong>Livelli bonus:</strong>
            <ul>
                <li>Dopo aver completato i 6 livelli obbligatori, potrai scegliere di proseguire con i livelli bonus (fino a 10 livelli bonus, per un totale di 16 livelli complessivi al massimo).</li>
                <li>Rispondendo alle domande bonus, avrai l'opportunità di accumulare punti extra e scalare la classifica.</li>
            </ul>
            <strong>Nota bene:</strong>
            <ul>
                <li>Dopo aver risposto a ciascuna domanda, una volta cliccato "<strong>Prosegui</strong>" visualizzerai una schermata di conferma della tua scelta. A questo punto, non sarà possibile modificare la risposta.</li>
                <li>Al termine di ogni livello, ti verrà mostrata una <strong>schermata di riepilogo</strong> delle risposte date.</li>
            </ul>

            Il test simula un quiz durante il quale potrebbero esserci degli attacchi informatici. <br />

            Nel quiz è stato inserito un meccanismo di sicurezza che dovrebbe aiutarti a rilevare se c'è un attacco informatico in corso. <br />

            Questo meccanismo mostra in alto all'interno della schermata un set di { type === 'emojis' ? 'emoji' : 'vocaboli' } casuali ogni volta che rispondi ad una domanda del test (in alto a sinistra per le preferenze personali e in alto a destra per le somme). Se uno di questi set cambia nel corso dello stesso livello, vuol dire che c'è un attacco in corso. <br />

            <strong>Se rilevi un attacco informatico</strong> in corso grazie a questo meccanismo di sicurezza, <strong>ti chiediamo di segnalare un problema</strong> con l'apposito pulsate.

            Gli attacchi informatici potrebbero non essere rilevati dal meccanismo di set di { type === 'emojis' ? 'emoji' : 'vocaboli' }, e <strong>causare i seguenti problemi:</strong>
            <ul>
                <li>Le opzioni di risposta visualizzate non sono coerenti con la domanda;</li>
                <li>l'opzione di risposta selezionata e la risposta mostrata nella schermata di conferma e/o nella schermata di riepilogo non corrispondono;</li>
                <li>non è presente il risultato corretto per la somma tra le opzioni disponibili.</li>
            </ul>
            <strong>Anche in questo caso ti chiediamo di segnalare un problema</strong> con l'apposito pulsante. <br />
            Segnalando un problema il livello risulterà completato e potrai proseguire con il livello successivo.
        </Overlay>
    )
}