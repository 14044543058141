import { Button, Grid, Typography } from "@mui/material";

export default function Info({ children, enableLeft, enableRight, enabledContinue, clickLeft, clickRight, clickContinue, num }) {
    return (
        <>
            <Grid item>
                <Typography variant="h4" className="mainTitle">
                    Stai per iniziare il test!
                </Typography>
            </Grid>

            <Grid item>
                <p className="mainTitle">
                    Leggi attentamente le istruzioni
                </p>
            </Grid>

            <Grid item style={{minHeight: '40svh'}}>
                { children }
            </Grid>

            <Grid container justifyContent="space-between" 
                alignItems="center"
                border="0.2em"
                padding="0.2em"
                style={{margin: "0px"}}>
                <p className="arrow" onClick={clickLeft}>{ enableLeft ? <>◂</> : <>◃</> }</p>
                <p>{num} / 9</p>
                <p className="arrow" onClick={clickRight}>{ enableRight ? <>▸</> : <>▹</> }</p>
            </Grid>

            <Grid item>
                <Button variant="contained" disabled={!enabledContinue} onClick={clickContinue}>INIZIA IL TEST</Button>
            </Grid>
        </>
    )
}