import { useEffect, useState } from "react"
import fetchAPI from "../utils/Fetcher"
import { Button, Grid, Typography } from "@mui/material"
import FurtherInformation from "../components/FurtherInformation"
import ReportOverlay from "./ReportOverlay"
import LevelInfoOverlay from "./LevelInfoOverlay"
import EncodingRow from "./EncodingRow"
import SummaryView from "../views/SummaryView"

export default function Summary({ type, level, setLevel, setQuestionStatus }) {
    // const [overlay, setOverlay] = useState(false)
    // const [report, setReport] = useState(false)

    const [answares, setAnswares] = useState(null)

    useEffect(() => {
        fetchAPI(`/questions/level/${level}/summary/show`)
            .then(j => setAnswares(j))
    }, [])

    if(answares === null) 
        return <p>Caricamento...</p>

    return <SummaryView
                    test_type={type}
                    summary_type="summary"
                    answares={ answares.answares.map(a => {return { value: a }}) }

                    encoding_type={type}
                    encoding_content={{
                        left: answares.topleft, 
                        right: answares.topright, 
                    }}

                    onSubmit={() =>
                        fetchAPI(`/questions/level/${level}/summary/submit`)
                            .then(j => {
                                if(j.has_next_level) {
                                    setLevel(l => l + 1)
                                } else {
                                    setLevel(null)
                                }
                                setQuestionStatus('level_info')
                            })
                    }

                    allow_submit={true}

                    onReport={() => 
                        fetchAPI(`/questions/level/${level}/summary/submit_report`)
                            .then(j => {
                                if(j.has_next_level) {
                                    setLevel(l => l + 1)
                                } else {
                                    setLevel(null)
                                }
                                setQuestionStatus('level_info')
                            })
                    }

                    onInfoState={s => fetchAPI(`/questions/level/${level}/summary/` + (s ? "info" : "close_info"))}
                    onReportState={s => fetchAPI(`/questions/level/${level}/summary/` + (s ? "report" : "close_report"))}

                    />

    // return (
    //     <>
    //         <EncodingRow testType={type} data={answares} />

    //         <Typography variant="h4" className="mainTitle">Riepilogo <br /> Livello</Typography>

    //         <Typography variant="h6" className="mainTitle">Hai scelto</Typography>

    //         {
    //             answares.answares.map(a => <p className="answare">{ a }</p>)
    //         }

    //         <Grid container
    //             direction="row"
    //             justifyContent="center"
    //             marginBottom="1em"
    //             spacing={2}>
    //             <Grid item>
    //                 <Button variant="outlined" onClick={() => {
    //                     fetchAPI(`/questions/level/${level}/summary/report`)
    //                         .then(() => setReport(true))
    //                 }}>Segnala un problema</Button>
    //             </Grid>
    //             <Grid item>
    //                 <Button variant="contained" onClick={() =>
    //                     fetchAPI(`/questions/level/${level}/summary/submit`)
    //                         .then(j => {
    //                             if(j.has_next_level) {
    //                                 setLevel(l => l + 1)
    //                             } else {
    //                                 setLevel(null)
    //                             }
    //                             setQuestionStatus('level_info')
    //                         })
    //                 }>PROSEGUI</Button>
    //             </Grid>
    //         </Grid>

    //         <FurtherInformation onClick={() => 
    //             fetchAPI(`/questions/level/${level}/summary/info`)
    //                 .then(() => setOverlay(true))
    //         } />

    //         <ReportOverlay type={type}
    //             open={report}
    //             onClose={() =>
    //                 fetchAPI(`/questions/level/${level}/summary/close_report`)
    //                     .then(() => setReport(false))
    //             }
    //             onReport={() => {
    //                 fetchAPI(`/questions/level/${level}/summary/submit_report`)
    //                     .then(j => {
    //                         if(j.has_next_level) {
    //                             setLevel(l => l + 1)
    //                         } else {
    //                             setLevel(null)
    //                         }
    //                         setQuestionStatus('level_info')
    //                     })
    //             }} />


    //         <LevelInfoOverlay type={type}
    //             open={overlay}
    //             onClose={() => {
    //                 fetchAPI(`/questions/level/${level}/summary/close_info`)
    //                     .then(() => setOverlay(false))
    //             }} />
    //     </>
    // )
}
