import { Grid } from "@mui/material";
import Dots from "../components/Dots";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import QuestionView from "../views/QuestionView";

export default function Details({ setIntroStatus }) {
    return (
        <QuestionView
            enable_encoding={false}
            question="Quando osservi qualcosa, quanta attenzione presti ai dettagli?"
            question_type="text"
            answares={[
                { value: "Nessuna", answare: "Nessuna" },
                { value: "Poca", answare: "Poca" },
                { value: "Abbastanza", answare: "Abbastanza" },
                { value: "Molta", answare: "Molta" },
                { value: "Moltissima", answare: "Moltissima" },
            ]}

            enable_report={false}
            enable_info={false}

            enable_steps={true}
            steps_current={5}
            steps_total={7}

            onSubmit={ans => {
                fetchAPI('/intro/question/details/submit', {}, {"answare": ans})
                    .then(() => setIntroStatus('awareness'))
            }}

            />


        // <>
        //     <TextQuestion question="Quando osservi qualcosa, quanta attenzione presti ai dettagli?" 
        //             answares={[
        //                 "Nessuna", 
        //                 "Poca", 
        //                 "Abbastanza", 
        //                 "Molta", 
        //                 "Moltissima" 
        //             ]} setAnsware={ans => {
        //                 fetchAPI('/intro/question/details/submit', {}, {"answare": ans})
        //                     .then(() => setIntroStatus('awareness'))
        //             }} />

        //     <Grid item>
        //         <Dots index={5}
        //             total={7} />
        //     </Grid>
        // </>
    );
}