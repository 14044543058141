import { Grid } from "@mui/material";
import Dots from "../components/Dots";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import QuestionView from "../views/QuestionView";

export default function Colors({ setIntroStatus }) {
    return (
        <QuestionView
            enable_encoding={false}
            question="Hai difficoltà a distinguere tra determinati colori?"
            question_type="text"
            answares={[
                { value: "No, distinguo bene i colori", answare: "No, distinguo bene i colori" },
                { value: "Sì, ho una forma di discromatopsia (es., daltonismo)", answare: "Sì, ho una forma di discromatopsia (es., daltonismo)" },
                { value: "Sì, non vedo i colori", answare: "Sì, non vedo i colori" },
            ]}

            enable_report={false}
            enable_info={false}

            enable_steps={true}
            steps_current={3}
            steps_total={7}

            onSubmit={ans => {
                fetchAPI('/intro/question/colors/submit', {}, {"answare": ans})
                    .then(() => setIntroStatus('attention'))
            }}

            />

        // <>
        //     <TextQuestion question="Hai difficoltà a distinguere tra determinati colori?" 
        //             answares={[
        //                 "No, distinguo bene i colori", 
        //                 "Sì, ho una forma di discromatopsia (es., daltonismo)",
        //                 "Sì, non vedo i colori"
        //             ]} setAnsware={ans => {
        //                 fetchAPI('/intro/question/colors/submit', {}, {"answare": ans})
        //                     .then(() => setIntroStatus('attention'))
        //             }} />

        //     <Grid item>
        //         <Dots index={3}
        //             total={7} />
        //     </Grid>
        // </>
    );
}