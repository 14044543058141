import { Drawer, Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function Overlay({ children, title, showClose, open, onClose }) {
    return (
        <Drawer open={open} onClose={onClose} anchor="bottom" className="overlay">
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                >

                    <Grid
                    container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="2em"
                        >
                            <Grid item xs={showClose ? 11 : 12}>
                                <Typography variant="h5" className="mainTitle" >{ title }</Typography>
                            </Grid>
                            { showClose &&
                                <Grid item xs={1}>
                                    <span onClick={onClose}>
                                        <CloseIcon className="pointer" />
                                    </span>
                                </Grid>
                            }
                    </Grid>

                    <Grid item
                        marginTop="1em"
                        className="alignLeft"
                        padding="2.3em"
                        paddingTop="0">

                        { children }
                    </Grid>
            </Grid>
        </Drawer>
    )
}