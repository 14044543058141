import { Box, Card, Grid } from "@mui/material";
import { useState } from "react";

export default function EncodingRow({ testType, data }) {
    const [openLeft, setOpenLeft] = useState(false)
    const [openRight, setOpenRight] = useState(false)

    return (
        <>
            <Grid item>
                <Grid   container 
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={8}>
                    { !openRight &&
                        <Grid item xs={!openLeft ? 6 : 12}>
                            {
                                (data.topleft !== null ) && (
                                    testType === 'emojis' ?
                                    ( <span className="emoji">{data.topleft[0]}</span> ) :
                                    ( 
                                        openLeft ?
                                            (
                                                <Box onClick={() => setOpenLeft(false)}>
                                                    <Card variant="outlined">
                                                        <Grid container spacing={1} padding="0.5em" fontSize="25pt">
                                                            { data.topleft.map(e => 
                                                                <Grid item xs={6}>
                                                                    { e }
                                                                </Grid>
                                                            ) }
                                                        </Grid>
                                                    </Card>
                                                </Box>
                                            )
                                            :
                                            (
                                                <Grid container spacing={0.5}
                                                    fontSize="10pt"
                                                    onClick={() => setOpenLeft(true)}>
                                                    { data.topleft.map(e => 
                                                        <Grid item xs={6}>
                                                            { e }
                                                        </Grid>
                                                    ) }
                                                </Grid>
                                            )
                                    )
                                )
                            }
                        </Grid>
                    }
                    {
                        !openLeft &&
                        <Grid item xs={!openRight ? 6 : 12}>
                            {
                                (data.topright !== null) && (
                                    testType === 'emojis' ?
                                    ( <span className="emoji">{data.topright[0]}</span> ) :
                                    (
                                        openRight ?
                                        (
                                            <Box onClick={() => setOpenRight(false)}>
                                                <Card variant="outlined">
                                                    <Grid container spacing={1} padding="0.5em" fontSize="25pt">
                                                        { data.topright.map(e => 
                                                            <Grid item xs={6}>
                                                                { e }
                                                            </Grid>
                                                        ) }
                                                    </Grid>
                                                </Card>
                                            </Box>
                                        )
                                        :
                                        (
                                            <Grid container spacing={0.5}
                                                fontSize="10pt"
                                                onClick={() => setOpenRight(true)}>
                                                { data.topright.map(e => 
                                                    <Grid item xs={6}>
                                                        { e }
                                                    </Grid>
                                                ) }
                                            </Grid>
                                        )

                                        // <Grid container spacing={0.5}>
                                        //     { data.topright.map(e => 
                                        //         <Grid item xs={6}>
                                        //             { e }
                                        //         </Grid>
                                        //     ) }
                                        // </Grid>
                                    )
                                )
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}