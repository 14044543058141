import { Grid } from "@mui/material";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import Dots from "../components/Dots";
import QuestionView from "../views/QuestionView";

export default function Missing({ setSurveyStatus }) {
    return <QuestionView
                    enable_encoding={false}

                    question_type="text"
                    question={
                        <>
                            <p>Quante volte hai riscontrato il seguente errore:</p>
                            <p><strong>"Non è presente il risultato corretto per la somma tra le opzioni disponibili"</strong></p>
                        </>
                    }
                    answares={[
                        { value: '0', answare: '0'} ,
                        { value: '1', answare: '1'} ,
                        { value: '2', answare: '2'} ,
                        { value: '3', answare: '3'} ,
                        { value: '4', answare: '4'} ,
                        { value: '5+', answare: '5+'} 
                    ]}

                    enable_report={false}
                    enable_info={false}

                    enable_steps={true}
                    steps_current={4}
                    steps_total={6}

                    onSubmit={a => {
                        fetchAPI('/survey/question/missing/submit', {}, { 'answare': a })
                            .then(() => setSurveyStatus('reported'))
                    }}

                    />


    // return (
    //     <>
    //         <TextQuestion question={
    //                     <>
    //                         <p>Quante volte hai riscontrato il seguente errore:</p>
    //                         <p><strong>"Non è presente il risultato corretto per la somma tra le opzioni disponibili"</strong></p>
    //                     </>
    //                 }
    //                 answares={[
    //                     '0',
    //                     '1',
    //                     '2',
    //                     '3',
    //                     '4',
    //                     '5+'
    //                 ]}
    //                 setAnsware={a => {
    //                     fetchAPI('/survey/question/missing/submit', {}, { 'answare': a })
    //                         .then(() => setSurveyStatus('reported'))
    //                 }} />

    //         <Grid item>
    //             <Dots index={4}
    //                 total={6} />
    //         </Grid>
    //     </>
    // )
}