import { useEffect } from "react";
import Info from "../components/Info";
import fetchAPI from "../utils/Fetcher"

export default function Info7({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/7/show") })

    return (
        <Info enableLeft={true} enableRight={true} enabledContinue={false} 
            num={7}
            clickLeft={() => setQuestionStatus('info_6')}
            clickRight={() => setQuestionStatus('info_8')}
            clickContinue={() => {}}>
            <p>
                Gli attacchi informatici potrebbero non essere rilevati dal meccanismo di set di { type === 'emojis' ? 'emoji' : 'vocaboli' }, e <strong>causare i seguenti problemi:</strong>
                <ul>
                    <li>Le opzioni di risposta visualizzate non sono coerenti con la domanda;</li>
                    <li>l'opzione di risposta selezionata e la risposta mostrata nella schermata di conferma e/o nella schermata di riepilogo non corrispondono;</li>
                    <li>non è presente il risultato corretto per la somma tra le opzioni disponibili.</li>
                </ul>
                <strong>Anche in questo caso ti chiediamo di segnalare un problema</strong> con l'apposito pulsante. <br />
                Segnalando un problema il livello risulterà completato e potrai proseguire con il livello successivo.
            </p>
        </Info>
    )
}