import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";

export default function TextQuestion({ children, question, answares, setAnsware }) {
    const [ans, setAns] = useState(null);

    console.log(children)

    return (
        <>
        {/* // <Grid   container
        //         direction="column"
        //         justifyContent="space-between"
        //         alignItems="stretch"> */}
            <Grid item>
                <div className="questionTitle">
                    { 
                        (typeof question === 'string' || question instanceof String) ? 
                        question.split('\\n').map(e => <p>{ e }</p>) :
                        question 
                    }
                 </div>
            </Grid>

            <Grid item>
                <FormControl>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
                        {
                            answares.map(a => 
                                <FormControlLabel value={a} control={<Radio />} label={
                                    <p className="answare" style={{ textAlign: 'left' }}>{a}</p>
                                } onClick={() => {
                                    setAns(a);
                                }} />
                            )
                        }
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                        <span style={{ marginRight: '1em' }}>
                            { children !== undefined && children }
                        </span>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={ans === null} onClick={() => setAnsware(ans)}>PROSEGUI</Button>
                    </Grid>
                </Grid>
                {/* { children !== undefined && children[1] } */}
            </Grid>
        {/* // </Grid> */}
        </>

        // <>
        //     <Grid item>
        //         <div className="questionTitle">
        //             { 
        //                 (typeof question === 'string' || question instanceof String) ? 
        //                 question.split('\\n').map(e => <p>{ e }</p>) :
        //                 question 
        //             }
        //         </div>
        //     </Grid>


            // <Grid item xs={12}>
            //     <FormControl>
            //         <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
            //             {
            //                 answares.map(a => 
            //                     <FormControlLabel value={a} control={<Radio />} label={a} onClick={() => {
            //                         setAns(a);
            //                     }} />
            //                 )
            //             }
            //         </RadioGroup>
            //     </FormControl>
            // </Grid>

        //     <Grid item>
        //         <span style={{ marginRight: '1em' }}>
        //             { children }
        //         </span>
        //         <Button variant="contained" disabled={ans === null} onClick={() => setAnsware(ans)}>PROSEGUI</Button>
        //     </Grid>
        // </>
    );
}