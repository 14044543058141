import { useEffect } from "react";
import Info from "../components/Info";
import fetchAPI from "../utils/Fetcher"

export default function Info4({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/4/show") })

    return (
        <Info enableLeft={true} enableRight={true} enabledContinue={false} 
            num={4}
            clickLeft={() => setQuestionStatus('info_3')}
            clickRight={() => setQuestionStatus('info_5')}
            clickContinue={() => {}}>
            <p>
                <strong>Livelli 5 e 6 (obbligatori)</strong><br />
                Ogni livello contiene due domande. <br />
                Come nei livelli 3 e 4, nella prima domanda dovrai esprimere la tua preferenza tra le opzioni disponibili. <br />
                Nella seconda domanda, dovrai risolvere una somma a due cifre e scegliere il risultato corretto tra le opzioni fornite.
            </p>
        </Info>
    )
}