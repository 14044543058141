import { useEffect, useState } from "react"
import fetchAPI from "../utils/Fetcher"
import { Button, Grid, Typography } from "@mui/material"
import FurtherInformation from "../components/FurtherInformation"
import ReportOverlay from "./ReportOverlay"
import LevelInfoOverlay from "./LevelInfoOverlay"
import EncodingRow from "./EncodingRow"
import SummaryView from "../views/SummaryView"

export default function Confirmation({ type, level, question, setQuestion, setQuestionStatus, setLevel }) {

    const [answare, setAnsware] = useState(null)

    useEffect(() => {
        fetchAPI(`/questions/level/${level}/${question}/confirmation/show`)
            .then(j => setAnsware(j))
    }, [])

    if(answare === null) 
        return <p>Caricamento...</p>


    return <SummaryView
                    test_type={type}
                    summary_type="confirmation"
                    answares={[{ value: answare.answare }]}

                    encoding_type={type}
                    encoding_content={{
                        left: answare.topleft, 
                        right: answare.topright, 
                    }}

                    onSubmit={() =>
                        fetchAPI(`/questions/level/${level}/${question}/confirmation/submit`)
                            .then(j => {
                                if(j.has_next_submission) {
                                    setQuestion(q => q + 1)
                                    setQuestionStatus('question')
                                } else {
                                    setQuestionStatus('summary')
                                }
                            })
                    }

                    allow_submit={true}

                    onReport={() => fetchAPI(`/questions/level/${level}/${question}/confirmation/submit_report`)
                                        .then(j => {
                                            if(j.has_next_level) {
                                                setLevel(l => l + 1)
                                            } else {
                                                setLevel(null)
                                            }
                                            setQuestionStatus('level_info')
                                        })
                    }

                    onInfoState={s => fetchAPI(`/questions/level/${level}/${question}/confirmation/` + (s ? "info" : "close_info"))}
                    onReportState={s => fetchAPI(`/questions/level/${level}/${question}/confirmation/` + (s ? "report" : "close_report"))}

                    />


    // return (
    //     <>
    //         <EncodingRow testType={type} data={answare} />

    //         <Typography variant="h4" className="mainTitle">Conferma</Typography>

    //         <Typography variant="h6" className="mainTitle">Hai scelto</Typography>

    //         <p className="answare">{ answare.answare }</p>

    //         <Grid container
    //             direction="row"
    //             justifyContent="center"
    //             marginBottom="1em"
    //             spacing={2}>
    //             <Grid item>
    //                 <Button variant="outlined" onClick={() => {
    //                     fetchAPI(`/questions/level/${level}/${question}/confirmation/report`)
    //                         .then(() => setReport(true))
    //                 }}>Segnala un problema</Button>
    //             </Grid>
    //             <Grid item>
    //                 <Button variant="contained" onClick={() =>
    //                     fetchAPI(`/questions/level/${level}/${question}/confirmation/submit`)
    //                         .then(j => {
    //                             if(j.has_next_submission) {
    //                                 setQuestion(q => q + 1)
    //                                 setQuestionStatus('question')
    //                             } else {
    //                                 setQuestionStatus('summary')
    //                             }
    //                         })
    //                 }>PROSEGUI</Button>
    //             </Grid>
    //         </Grid>

    //         <FurtherInformation onClick={() => 
    //             fetchAPI(`/questions/level/${level}/${question}/confirmation/info`)
    //                 .then(() => setOverlay(true))
    //         } />

    //         <ReportOverlay type={type}
    //             open={report}
    //             onClose={() =>
    //                 fetchAPI(`/questions/level/${level}/${question}/confirmation/close_report`)
    //                     .then(() => setReport(false))
    //             }
    //             onReport={() => {
    //                 fetchAPI(`/questions/level/${level}/${question}/confirmation/submit_report`)
    //                     .then(j => {
    //                         if(j.has_next_level) {
    //                             setLevel(l => l + 1)
    //                         } else {
    //                             setLevel(null)
    //                         }
    //                         setQuestionStatus('level_info')
    //                     })
    //             }} />


    //         <LevelInfoOverlay type={type}
    //             open={overlay}
    //             onClose={() => {
    //                 fetchAPI(`/questions/level/${level}/${question}/confirmation/close_info`)
    //                     .then(() => setOverlay(false))
    //             }} />
    //     </>
    // )
}
