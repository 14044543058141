import { Box, Card, Grid } from "@mui/material";
import { useState } from "react";
import TutorialBox from "./TutorialBox";

function EncodingWordsComponent({ data, curStatus, status, setStatus, enable_highlighted }) {
    if(status !== "close" && status !== curStatus) {
        return <></>
    }

    if(curStatus === status) {
        return (
            <Grid item xs={12}>
                <Box onClick={() => setStatus("close")}>
                    <Card variant="outlined">
                        <Grid container spacing={1} padding="0.5em" fontSize="25pt">
                            { data.map(e => <Grid item xs={6}>{ e }</Grid>) }
                        </Grid>
                    </Card>
                </Box>
            </Grid>
        )
    } else {
        return (
            <Grid item xs={6}>
                <TutorialBox enable={enable_highlighted}>
                    <Grid container spacing={0.5}
                            fontSize="10pt"
                            onClick={() => setStatus(curStatus)}>
                        { data.map(e => <Grid item xs={6}>{ e }</Grid>) }
                    </Grid>
                </TutorialBox>
            </Grid>
        )
    }
}

function EncodingWords({ content, enable_left_highlighted, enable_right_highlighted }) {
    const [status, setStatus] = useState("close")

    return (
        <>
            { (content.left !== undefined && content.left !== null) && 
                <EncodingWordsComponent data={content.left} curStatus={"left"} status={status} setStatus={setStatus}
                        enable_highlighted={enable_left_highlighted} /> }

            { ( content.right !== undefined && content.right !== null) && 
                <EncodingWordsComponent data={content.right} curStatus={"right"} status={status} setStatus={setStatus}
                        enable_highlighted={enable_right_highlighted} /> }
        </>
    )
}

function EncodingEmoji({ content, enable_left_highlighted, enable_right_highlighted }) {
    return (
        <>
            <Grid item xs={6}>
                { content.left !== undefined && 
                    <TutorialBox enable={enable_left_highlighted}>
                        <span className="emoji">{content.left}</span>
                    </TutorialBox> }
            </Grid>

            <Grid item xs={6}>
                { content.right !== undefined && 
                    <TutorialBox enable={enable_right_highlighted}>
                        <span className="emoji">{content.right}</span>
                    </TutorialBox> }
            </Grid>
        </>
    )
}

export default function EcnodingView({ type, content, 
        enable_left_highlighted, enable_right_highlighted }) {

    return (
        <Grid   
                container 
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={8}>

            { type === 'words' && <EncodingWords content={content} 
                    enable_left_highlighted={enable_left_highlighted}
                    enable_right_highlighted={enable_right_highlighted} /> }

            { type === 'emojis' && <EncodingEmoji content={content} 
                    enable_left_highlighted={enable_left_highlighted}
                    enable_right_highlighted={enable_right_highlighted} /> }

        </Grid>
    )
}