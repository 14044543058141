import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import configs from "../Config";

export default function ImgQuestion({ children, question, answares, setAnsware }) {
    const [ans, setAns] = useState(null);

    console.log(children)

    return (
        <>
            <Grid item>
                <div className="questionTitle">
                    { 
                        (typeof question === 'string' || question instanceof String) ? 
                        question.split('\\n').map(e => <p>{ e }</p>) :
                        question 
                    }
                 </div>
            </Grid>

            <Grid item>
                <FormControl>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
                        {
                            answares.map(a => 
                                <>
                                <FormControlLabel value={a.text} control={<Radio />} 
                                        label={
                                            <img src={configs.url + "/static/img/" + a.path} /> 
                                        } onClick={() => {
                                    setAns(a.text);
                                }} />
                                <p className="answare">{a.text}</p>
                                </>
                            )
                        }
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                        <span style={{ marginRight: '1em' }}>
                            { children !== undefined && children }
                        </span>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled={ans === null} onClick={() => setAnsware(ans)}>PROSEGUI</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}