import { useEffect, useState } from "react";
import fetchAPI from "../utils/Fetcher"
import { Button, Typography } from "@mui/material";
import QuestionView from "../views/QuestionView";
import SummaryView from "../views/SummaryView";
import LevelRankView from "../views/LevelRankView";

function sampleEncoding2(type, wrong=false) {
    if(type === "emojis") {
        return { left: (!wrong ? "⛔📪🐦🧺🍀" : "👰✊😷🧉😚") }
    } else {
        return { left: (!wrong ? ['trattato', 'incrocio', 'avere', 'satollo'] : ['lavagna', 'abaco', 'emotivo', 'ferro']) }
    }
}

function sampleEncoding1(type, full=false) {
    if(type === "emojis") {
        return { left: "👀🎉🌉👘🥏", right: (full ? '🍪🆖👛🌚🏦' : undefined) }
    } else {
        return { left: ['europa', 'opposto', 'appunto', 'dormire'], right: (full ? ['ribadire', 'prudente', 'ammenda', 'frollino'] : undefined) }
    }
}

function Intro({ setStatus }) {
    return (
        <>
            <section style={{ backgroundColor: "#E5FFEA", margin: "-1em", padding: "1em", height: "110svh" }}>
                <Typography variant="h3">Tutorial</Typography>

                <p>Il test è diviso in <strong>livelli</strong>.</p>
                <p>In ogni livello potrebbe essere <strong>simulato un attacco informatico</strong>.</p>
                <p>Il tuo compito è rispondere a delle domande e <strong>segnalare</strong> quando ti accorgi che c’è un attacco in corso.</p>
                <p>Completando con successo i livelli accumulerai dei punti e potrai scalare la classifica (anonimizzata).</p>
                <p><strong>Vediamo ora come funziona un livello</strong></p>
                <br />
                <Button variant="contained" onClick={() => setStatus("level1")}>PROSEGUI</Button>
            </section>
        </>
    )
}

function Level1({ type, setStatus }) {
    const [lvlStatus, setLvlStatus] = useState("question1")
    const [answare1, setAnsware1] = useState(null)
    const [answare2, setAnsware2] = useState(null)

    if(lvlStatus === "question1") {
        return <Question1 type={type} setStatus={setLvlStatus} answare={answare1} setAnsware={setAnsware1} />
    } else if(lvlStatus === "question2") {
        return <Question2 type={type} setStatus={setLvlStatus} answare={answare2} setAnsware={setAnsware2} />
    } else if(lvlStatus === "summary") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="summary"
                        answares={[
                            { value: answare1, highlighted: "green" },
                            { value: /* "5 + 12 = "  + */ answare2, highlighted: "green" },
                        ]} 

                        allow_info={false}

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type, true)}
                        enable_left_highlighted="green"
                        enable_right_highlighted="green"
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>
                                    Controlla anche un’ultima volta che i set di { type === 'emojis' ? 'emoji' : 'vocaboli' } in alto 
                                    (sia a sinistra che a destra) non <strong>siano cambiati</strong>.
                                </p>
                                <p>
                                    <u>
                                        Se uno dei due fosse <strong>diverso</strong>, vuol dire che c’è un <strong>attacco</strong>
                                    </u> e ti chiediamo di segnalarlo.
                                </p>
                                <p>
                                    Dopo aver controllato bene tutto, se non ci sono anomalie 
                                    premi <strong><em>TERMINA LIVELLO</em></strong> per concludere il livello in corso.
                                </p>
                            </>,
                            <>
                                <p>
                                    Alla fine del livello ti sarà mostrata una schermata di riepilogo con tutte le risposte che hai selezionato.
                                </p>
                                <p>
                                    <strong>
                                        Controlla nuovamente che le risposte mostrate siano quelle che hai scelto.
                                    </strong>
                                </p>
                                <p>
                                    <u>
                                        Se vedi risposte <strong>diverse</strong> vuol dire che c’è 
                                        un <strong>attacco in corso</strong> e ti chiediamo di segnalarlo.
                                    </u>
                                </p>
                            </>
                        ].reverse()}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setStatus("level2")}
                        />
    }
}

function Question1({ type, setStatus, answare, setAnsware }) {
    const [subStatus, setSubStatus] = useState("question")
    const [selected, setSelected] = useState(null)

    if(subStatus === "question" || subStatus === "checked") {
        return <QuestionView 
                        key={subStatus}
                        test_type={type}
                        question_type="image"
                        question="Quale fiore preferisci tra questi?"
                        answares={[
                            { value: "margherita", answare: "Margherita", path: "margherita.png", highlighted: (subStatus === 'question' ? "yellow" : false) },
                            { value: "tulipano", answare: "Tulipano", path: "tulipano.png", highlighted: (subStatus === 'question' ? "yellow" : false) }
                        ]}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>In ogni livello ti verrà posta una domanda che chiederà una tua preferenza.</p>
                                <p>
                                    Rispondi selezionando liberamente l’opzione preferita e poi premi 
                                    su <strong><em>PROSEGUI</em></strong> per confermare e andare avanti.
                                </p>
                                <p>
                                    Dopo aver premuto su <strong><em>PROSEGUI</em></strong> non potrai più tornare 
                                    indietro a modificare la risposta.
                                </p>
                            </>
                        ].reverse()}

                        default_state={subStatus !== 'checked' ? 0 : 10}

                        allow_info={false}

                        selected={selected}
                        enable_tutorial_button={true}
                        onSelectionChange={a => {
                            setSelected(a)
                            setSubStatus("checked")} 
                        }
                        highlighted_submit={subStatus === 'checked' ? 'yellow' : false}
                        allow_report={false}
                        onSubmit={ans => {
                            setAnsware(ans)
                            setSubStatus("confirmation")}
                        }/>
    } else if(subStatus === "confirmation") {
        return <SummaryView 
                        key={subStatus}
                        test_type={type}
                        summary_type="confirmation"
                        answares={[{ value: answare, highlighted: "green" }]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type)}
                        enable_left_highlighted="green"

                        allow_info={false}
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>A partire dalla schermata di conferma appare in alto a sinistra un set di { type === 'emojis' ? 'emoji' : 'vocaboli' } casuali.</p>
                                <p>
                                    Fa parte di un meccanismo di <strong>sicurezza</strong> che dovrebbe aiutarti a rilevare se c’è un attacco informatico in corso:&nbsp;
                                    <u>se questo set <strong>cambia</strong> prima della fine del livello, vuol dire che c’è un <strong>attacco in corso</strong></u> e ti 
                                    chiediamo di segnalarlo.
                                </p>
                                <p>Dopo aver osservato bene premi <strong><em>PROSEGUI</em></strong></p>
                            </>,
                            <>
                                <p>Dopo che avrai risposto ti sarà mostrata una schermata di conferma.</p>
                                <p><strong>Controlla che la risposta mostrata sia quella che hai scelto tu.</strong></p>
                                <p>
                                    <u>Se vedi una <strong>risposta</strong> diversa vuol dire che c’è un <strong>attacco in corso</strong> e 
                                        ti chiediamo di segnalarlo premendo il bottone</u>
                                    <strong><em>&nbsp;Segnala un problema.</em></strong>
                                </p>
                            </>
                        ].reverse()}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setStatus("question2")}
                        />
        }
}

function Question2({ type, setStatus, answare, setAnsware }) {
    const [subStatus, setSubStatus] = useState("question")
    const [selected, setSelected] = useState(null)

    if(subStatus === "question" || subStatus === "checked") {
        return <QuestionView 
                        key={subStatus}
                        test_type={type}
                        question_type="text"
                        question="Quanto fa 5 + 12?"
                        answares={[
                            { value: "16", answare: "16"},
                            { value: "19", answare: "19"},
                            { value: "17", answare: "17", highlighted: (subStatus === 'question' ? "yellow" : false)},
                            { value: "18", answare: "18"},
                        ]}

                        allow_info={false}

                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>
                                    Oltre a risolvere la somma, ricordati di controllare anche che il set 
                                    di { type === 'emojis' ? 'emoji' : 'vocaboli' } <strong>non sia cambiato</strong>
                                </p>
                            </>,
                            <>
                                <p>
                                    Dal <strong>terzo</strong> livello in poi, oltre alla domanda sulla preferenza 
                                    ti sarà chiesto anche di risolvere una semplice <strong>somma</strong>.
                                </p>
                                <p>
                                    Rispondi selezionando la <strong>risposta corretta</strong> e poi premi 
                                    su <strong><em>PROSEGUI</em></strong> per confermare e andare avanti 
                                    (anche qui <u>non potrai più tornare indietro</u>).
                                </p>
                                <p>
                                    <u>
                                        Se <strong>non è presente la risposta esatta</strong> tra le opzioni visualizzate, 
                                        vuol dire che c’è un <strong>attacco in corso</strong> e ti chiediamo di segnalarlo.
                                    </u>
                                </p>
                            </>
                        ].reverse()}
                        
                        default_state={subStatus !== 'checked' ? 0 : 10}

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type)}
                        enable_left_highlighted="green"

                        selected={selected}
                        enable_tutorial_button={true}
                        onSelectionChange={a => {
                            setSelected(a)
                            setSubStatus("checked")
                        }}
                        highlighted_submit={subStatus === 'checked' ? 'yellow' : false}
                        allow_report={false}
                        onSubmit={ans => {
                            setAnsware(ans)
                            setSubStatus("confirmation")}
                        }/>
    } else if(subStatus === "confirmation") {
        return <SummaryView 
                        key={subStatus}
                        test_type={type}
                        summary_type="confirmation"
                        answares={[{ value: answare, highlighted: "green" }]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type, true)}
                        enable_left_highlighted="green"
                        enable_right_highlighted="green"

                        allow_info={false}
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>
                                    Ricordati di controllare anche che il primo set di { type === 'emojis' ? 'emoji' : 'vocaboli' } 
                                    &nbsp;(a sinistra) <strong>non sia cambiato</strong>.
                                </p>
                                <p>Dopo aver controllato bene premi <strong><em>PROSEGUI</em></strong></p>
                            </>,
                            <>
                                <p>
                                    A partire dalla schermata di conferma della somma appare in alto a&nbsp;
                                    <strong>destra</strong> un <strong>secondo</strong> set 
                                    di { type === 'emojis' ? 'emoji' : 'vocaboli' }  casuali.
                                </p>
                                <p>
                                    Anche questo fa parte del meccanismo di <strong>sicurezza</strong> per rilevare gli attacchi.
                                </p>
                                <p>
                                    Anche questo set, &nbsp;
                                    <u>
                                        se <strong>cambia</strong> prima della fine del livello, vuol dire che c’è un <strong>attacco</strong>
                                    </u>&nbsp;
                                    e ti chiediamo di segnalarlo.
                                </p>
                            </>,
                            <>
                                <p>Anche dopo la somma ti sarà mostrata una schermata di conferma.</p>
                                <p><strong>Controlla che la risposta mostrata sia quella che hai scelto tu.</strong></p>
                                <p>
                                    <u>
                                        Se vedi una risposta <strong>diversa</strong> vuol dire che 
                                        c’è un <strong>attacco in corso</strong> e ti chiediamo di segnalarlo.
                                    </u>
                                </p>
                            </>
                        ].reverse()}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setStatus("summary")}
                        />
        }

}

function Level2({ type, setStatus }) {
    const [lvlStatus, setLvlStatus] = useState("lvl_intro")
    const [selected, setSelected] = useState(false)

    if(lvlStatus === "lvl_intro") {
        return <LevelRankView 
                        key={lvlStatus}
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Dopo aver terminato ogni livello viene mostrata una pagina di progresso del test.</p>
                                <p>Il test si compone di 6 livelli obbligatori, seguiti da livelli bonus opzionali.</p>
                                <p>
                                    Alla fine ti chiederemo di <strong>compilare un questionario</strong> conclusivo sull’andamento del test, 
                                    in particolare quanti e quali <strong>attacchi informatici</strong> hai riscontrato.
                                </p>
                                <p>
                                    Ora premi <strong><em>INIZIA LIVELLO 2</em></strong>: vedremo un esempio di attacco informatico e come segnalarlo.
                                </p>
                            </>
                        ].reverse()}

                        enable_rank={false}
                        description={
                            <>
                                <p>Complimenti, hai completato il primo livello del Tutorial!</p>
                                <p>Premi il pulsante per continuare.</p>
                            </>
                        }
                        progress={1}
                        submit_text="INIZIA LIVELLO 2"
                        allow_submit={true}

                        allow_info={false}

                        onSubmit={() => setLvlStatus("question")}
                        />
    } else if(lvlStatus === "question") {
        return <QuestionView 
                        key={lvlStatus}
                        test_type={type}
                        question_type="text"
                        question="Quale forma preferisci tra queste?"
                        highlight_question="green"
                        answares={[
                            { value: "cerchio", answare: "Cerchio", highlighted: (!selected ? "yellow" : false)},
                            { value: "triangolo", answare: "Triangolo", highlighted: (!selected ? "yellow" : false)},
                            { value: "quadrato", answare: "Quadrato", highlighted: (!selected ? "yellow" : false)},
                            { value: "Esagono", answare: "Esagono", highlighted: (!selected ? "yellow" : false)},
                        ]}
                        allow_info={false}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>
                                    Oltre alle domande con due opzioni di risposta accompagnate da immagini, 
                                    ci sono anche domande con quattro opzioni di risposta senza immagini.
                                </p>
                                <p>
                                    A ogni domanda controlla che le <strong>opzioni di risposta siano coerenti con la domanda</strong>,&nbsp;
                                    <u>altrimenti staresti riscontrando un attacco informatico che ti chiediamo di segnalare</u>.
                                </p>
                                <p>
                                    Rispondi selezionando liberamente l’opzione preferita e poi premi 
                                    su <strong><em>PROSEGUI</em></strong> per confermare e andare avanti.
                                </p>
                            </>
                        ].reverse()}

                        encoding_type={type}
                        encoding_content={sampleEncoding2(type)}
                        enable_left_highlighted="green"

                        enable_tutorial_button={true}
                        onSelectionChange={() => setSelected(true)}
                        highlighted_submit={selected ? 'yellow' : false}
                        allow_report={false}
                        onSubmit={ans => {
                            setSelected(ans)
                            setLvlStatus("confirmation")}
                        }/>
    } else if(lvlStatus === "confirmation") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="confirmation"
                        answares={[{ value: selected, highlighted: "green" }]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding2(type)}
                        enable_left_highlighted="green"
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>
                                    Come spiegato in precedenza, <strong>controlla che la risposta mostrata sia quella che hai scelto 
                                        tu, e osserva bene</strong> il set di { type === 'emojis' ? 'emoji' : 'vocaboli' }  che appare in alto a sinistra.
                                </p>
                                <p>
                                    Nota che è normale che il set sia diverso dal livello precedente: ogni livello ha i suoi set.
                                </p>
                                <p>
                                    Dopo aver osservato bene premi <strong><em>PROSEGUI</em></strong>
                                </p>
                            </>
                        ].reverse()}

                        allow_info={false}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setLvlStatus("summary")}
                        />
    } else if(lvlStatus === "summary") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="summary"
                        answares={[
                            { value: selected, highlighted: "green" },
                        ]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding2(type, true)}
                        enable_left_highlighted="red"

                        allow_info={false}
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>
                                    Nota che il <strong>set di { type === 'emojis' ? 'emoji' : 'vocaboli' } è cambiato</strong> prima della fine del livello!
                                </p>
                                <p>
                                    Vuol dire che c’è un <strong>attacco informatico in corso</strong>, quindi bisogna <strong>segnalarlo</strong>.
                                </p>
                                <p>
                                    Premi sul pulsante <strong><em>Segnala un problema</em></strong>
                                </p>
                            </>,
                            <>
                                <p>
                                    I primi 2 livelli non chiedono di fare una somma, quindi dalla schermata di conferma si passa direttamente al riepilogo di livello.
                                </p>
                                <p>
                                    <u>
                                        Controlla che <strong>la risposta mostrata sia quella che hai scelto tu</strong>, e verifica 
                                        che <strong>il set di { type === 'emojis' ? 'emoji' : 'vocaboli' } non sia cambiato</strong>.
                                    </u>
                                </p>
                            </>
                        ].reverse()}

                        allow_report={true}                        
                        allow_submit={false}
                        highlighted_report="yellow"
                        autoopen_report={false}

                        onReportState={() => setLvlStatus("summary_report")}
                        
                        />
    } else if(lvlStatus === "summary_report") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="summary"
                        answares={[
                            { value: selected, highlighted: "green" },
                        ]} 
                        encoding_type={type}
                        encoding_content={sampleEncoding2(type, true)}
                        enable_left_highlighted="red"
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>
                                    Avviando la segnalazione si apre una finestra <strong>che descrive come si possono manifestare gli attacchi informatici da segnalare.</strong>
                                </p>
                                <p>
                                    Puoi verificare che in questo esempio abbiamo riscontrato l’ultima 
                                    categoria: <strong>il meccanismo di sicurezza ha rilevato un 
                                        attacco in corso poiché un set di { type === 'emojis' ? 'emoji' : 'vocaboli' } è cambiato</strong>.
                                </p>
                                <p>
                                    Premi <strong><em>Segnala un problema</em></strong> per confermare la segnalazione e concludere il livello.
                                </p>
                            </>
                        ].reverse()}
                        allow_report={true}                        
                        allow_submit={false}
                        highlighted_report="yellow"
                        autoopen_report={true}
                        allow_info={false}
                        onReport={() => setStatus("level3")}
                        />
    }

}

function Level3({ setStatus }) {
    const [subStatus, setSubStatus] = useState("none")

    let content = null
    switch(subStatus) {
        case "none":
            content = <>
                <p>
                    Segnalando un problema il livello risulta completato e ti viene mostrata la schermata di progresso da dove puoi proseguire con il livello successivo.
                </p>
                <p>
                    Come esempio ti mostriamo un fac-simile della schermata che ti verrà mostrata <u>quando completerai i 6 livelli obbligatori</u>.
                </p>
                <p>
                    Puoi vedere una classifica che in modo anonimo confronta i tuoi risultati con quelli degli altri partecipanti al test.Se vorrai ottenere più punti e scalare la classifica, dovrai completare correttamente uno o più livelli bonus.
                </p>
                <p>
                    Prenditi ora il tempo per <strong>esplorare i vari pulsanti</strong>, premendoli ti <strong>spiegheremo come funzioneranno</strong> nel test.
                </p>
                <p>
                    Quando hai finito di esplorare premi <strong><em>TERMINA TUTORIAL E INIZIA IL TEST</em></strong>.
                </p>
            </>
            break

        case "survey":
            content = <>
                <p>
                    Premendo su <strong><em>Termina Test e vai al Sondaggio</em></strong> accederai al questionario finale 
                    che ti chiederà com’è andato il test, e quante e quali anomalie (attacchi informatici) hai riscontrato.
                </p>
                <p>
                    Puoi terminare il test e passare al questionario finale dopo aver completato i livelli 
                    obbligatori (<u>non potrai tornare indietro a fare altri livelli bonus</u>).
                </p>
                <p>
                    Quando hai finito di esplorare premi <strong><em>TERMINA TUTORIAL E INIZIA IL TEST</em></strong>.
                </p>
            </>
            break

        case "submit":
            content = <>
                <p>
                    Dopo aver completato i 6 livelli obbligatori, potrai scegliere di proseguire con i livelli bonus 
                    (fino a 10 livelli bonus, per un totale di 16 livelli complessivi al massimo).
                </p>
                <p>
                    Rispondendo alle domande bonus, avrai l'opportunità di accumulare punti extra e scalare la classifica.
                </p>
                <p>
                    <u>Per ottenere i punti bonus dovrai risolvere correttamente le somme e segnalare subito i problemi che riscontri.</u>
                </p>
                <p>
                    Quando hai finito di esplorare premi <strong><em>TERMINA TUTORIAL E INIZIA IL TEST</em></strong>.
                </p>
            </>
            break

        case "info_open":
        case "info_close":
            content = <>
                <p>
                    In ogni momento durante il test, premendo su <strong><em>Maggiori informazioni</em></strong> si 
                    aprirà una finestra che riporta un riassunto delle istruzioni date durante il tutorial, consultalo quando vuoi.
                </p>
                <p>
                    Dai pure un occhio a questo riassunto e poi continua a esplorare i vari pulsanti, premendoli 
                    ti spiegheremo come funzioneranno nel test.
                </p>
                <p>
                    Quando hai finito di esplorare premi <strong><em>TERMINA TUTORIAL E INIZIA IL TEST</em></strong>.
                </p>
            </>
            break
    }

    return <LevelRankView 
                    key={subStatus}
                    enable_tutorial_finish={true}
                    enable_tutorial_button={true}
                    enable_tutorial_overlay={true}
                    tutorial_overlay_content={[content].reverse()}
                    default_state={subStatus === "info_close" ? 1 : 0}

                    enable_rank={true}
                    data={[
                        [1, "AXY123", 2500 ],
                        [2, "RTE864", 2200 ],
                        [3, "SIR638", 1700 ],
                        [18, "YOU PSE856", 600 ],
                    ]}
                    description={
                        <>
                            <p>Complimenti, hai completato i 6 livelli obbligatori!</p>
                            
                            <p>
                                Se vuoi puoi continuare con i livelli bonus (fino ad un massimo di 10 livelli): completandoli correttamente 
                                potrai accumulare più punti ed entrare in classifica, attualmente sei al 18° posto:
                            </p>
                        </>
                    }
                    progress={1}
                    submit_text="INIZIA LIVELLO 2"
                    allow_submit={true}
                    enable_survey={true}

                    autoopen_info={subStatus === "info_open"}

                    onSurvey={() => setSubStatus("survey")}
                    onSubmit={() => setSubStatus("submit")}
                    onInfoState={() => {
                        if(subStatus === 'info_open') {
                            setSubStatus("info_close")
                        } else {
                            setSubStatus("info_open")
                        }
                    }}
                    onFinishTutorial={() => setStatus("finished")}
                    />
}

export default function Info9({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/9/show") })

    const [status, setStatus] = useState("intro")

    switch(status) {
        case "intro":
            return <Intro setStatus={setStatus} />

        case "level1":
            return <Level1 type={type} setStatus={setStatus} />

        case "level2":
            return <Level2 type={type} setStatus={setStatus} />

        case "level3":
            return <Level3 setStatus={setStatus} />

        case "finished":
            fetchAPI('/questions/info/start')
                    .then(() => setQuestionStatus('level_info'))
            return <p>Caricamento...</p>

        default: 
            return <p>Finished :D</p>
    }

}