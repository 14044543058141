import { useState } from "react"
import InfoManager from "./InfoManager"
import LevelInfo from "./LevelInfo"
import Question from "./Question"
import Confirmation from "./Confirmation"
import Summary from "./Summary"

export default function Questions({ type, initQuestionStatus, setSection, initLevel, initSubmission }) {
    const [level, setLevel] = useState(initLevel !== null ? initLevel : 1)
    const [question, setQuestion] = useState(initSubmission !== null ? initSubmission : 1)

    if(initQuestionStatus === null || initQuestionStatus === 'not_started')
        initQuestionStatus = "info_9"
    const [questionStatus, setQuestionStatus] = useState(initQuestionStatus)
    if(questionStatus === 'finished')
        setSection('survey')

    return (
        <>
            { questionStatus.startsWith('info_') && <InfoManager type={type} questionStatus={questionStatus} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'level_info' && <LevelInfo type={type} level={level} setQuestionStatus={setQuestionStatus} 
                                                            setLevel={setLevel} setQuestion={setQuestion} /> }
            { questionStatus === 'question' && <Question testType={type} setLevel={setLevel} level={level} question={question} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'confirmation' && <Confirmation type={type} setLevel={setLevel} level={level} question={question} 
                                                                setQuestion={setQuestion} setQuestionStatus={setQuestionStatus} /> }
            { questionStatus === 'summary' && <Summary level={level} type={type} setLevel={setLevel} setQuestionStatus={setQuestionStatus} /> }
        </>
    )
}
