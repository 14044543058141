import { useEffect } from "react";
import Info from "../components/Info";
import fetchAPI from "../utils/Fetcher"

export default function Info3({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/3/show") })

    return (
        <Info enableLeft={true} enableRight={true} enabledContinue={false} 
            num={3}
            clickLeft={() => setQuestionStatus('info_2')}
            clickRight={() => setQuestionStatus('info_4')}
            clickContinue={() => {}}>
            <p>
                <strong>Livelli 3 e 4 (obbligatori)</strong><br />
                Ogni livello contiene due domande. <br />
                La prima domanda ti richiederà di indicare la tua preferenza tra le opzioni disponibili. <br />
                La seconda domanda ti richiederà di risolvere una somma ad una cifra e selezionare il risultato corretto tra le opzioni disponibili.
            </p>
        </Info>
    )
}