import { useEffect } from "react";
import Info from "../components/Info";
import fetchAPI from "../utils/Fetcher"

export default function Info6({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/6/show") })

    return (
        <Info enableLeft={true} enableRight={true} enabledContinue={false} 
            num={6}
            clickLeft={() => setQuestionStatus('info_5')}
            clickRight={() => setQuestionStatus('info_7')}
            clickContinue={() => {}}>
            <p>
                Il test simula un quiz durante il quale potrebbero esserci degli attacchi informatici. <br />

                Nel quiz è stato inserito un meccanismo di sicurezza che dovrebbe aiutarti a rilevare se c'è un attacco informatico in corso. <br />

                Questo meccanismo mostra in alto all'interno della schermata un set di { type === 'emojis' ? 'emoji' : 'vocaboli' } casuali ogni volta che rispondi ad una domanda del test (in alto a sinistra per le preferenze personali e in alto a destra per le somme). Se uno di questi set cambia nel corso dello stesso livello, vuol dire che c'è un attacco in corso. <br />

                <strong>Se rilevi un attacco informatico</strong> in corso grazie a questo meccanismo di sicurezza, <strong>ti chiediamo di segnalare un problema</strong> con l'apposito pulsate.
            </p>
        </Info>
    )
}