import { useState } from "react"
import Welcome from "./Welcome";
import Age from "./Age";
import Dimension from "./Dimension";
import Colors from "./Colors";
import Attention from "./Attention";
import Details from "./Details";
import Awareness from "./Awareness";
import Emojis from "./Emojis";

export default function Intro({ type, initIntroState, setSection }) {
    const [introStatus, setIntroStatus] = useState(initIntroState == null ? "welcome" : initIntroState);

    if(introStatus === 'finished') {
        setSection('questions')
        return <></>;
    }

    return (
        <>
            { introStatus === "welcome" && <Welcome setIntroStatus={setIntroStatus} /> }
            { introStatus === "age" && <Age setIntroStatus={setIntroStatus} /> }
            { introStatus === "dimension" && <Dimension setIntroStatus={setIntroStatus} /> }
            { introStatus === "colors" && <Colors setIntroStatus={setIntroStatus} /> }
            { introStatus === "attention" && <Attention setIntroStatus={setIntroStatus} /> }
            { introStatus === "details" && <Details setIntroStatus={setIntroStatus} /> }
            { introStatus === "awareness" && <Awareness type={type} setIntroStatus={setIntroStatus} /> }
            { introStatus === "emojis" && <Emojis setIntroStatus={setIntroStatus} /> }
        </>
    );
}