import { Alert, Grid } from "@mui/material";
import Dots from "../components/Dots";
import { useState } from "react";
import CustomDimensionQuesiton from "./CustomDimensionQuestion";
import fetchAPI from "../utils/Fetcher";

export default function Dimension({ setIntroStatus }) {
    return (
        <>
            <CustomDimensionQuesiton question={
                <>
                <p className="questionTitle">
                    Qual è la dimensione minima del carattere che riesci a leggere comodamente?
                </p>
                <Alert severity="info">
                    <span className="answare">
                        la scelta non cambierà la dimensione del font durante il test
                    </span>
                </Alert>
                <br />
                </>
            }
            
                answare="Pranzo d'acqua fa volti sghembi" setAnsware={ans => {
                    fetchAPI('/intro/question/dimension/submit', {}, {"answare": ans})
                        .then(() => setIntroStatus('colors'))
                }} />

            <Grid item>
                <Dots index={2}
                    total={7} />
            </Grid>
        </>
    );
}