import { useState } from 'react';
import Intro from './intro/Intro';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './App.css';
import Questions from './questions/Questions';
import Survey from './survey/Survey';
import Finished from './components/Finished';


export default function Main({ state }) {
    const [section, setSection] = useState(state['section']);

    return (
        // <Box    display="flex"
        //         justifyContent="center"
        //         alignContent="center"
        //         minHeight="100svh"
        //         // border="0.1em"
        //         // paddingLeft="0.25em"
        //         // paddingRight="0.25em"
        //         flexGrow={1}>
        //     <Grid   container
        //             xs={12} sm={9} md={6} lg={4} p={4} 
        //             justifyContent="space-between"
        //             direction="column"
        //             alignItems="stretch"
        //             padding="1em">
                        
        <>
                { section === "intro" && <Intro type={state.type} initIntroState={state.intro} setSection={setSection} /> }
                { section === "questions" && <Questions type={state.type} initQuestionStatus={state.questions} setSection={setSection}
                                                        initLevel={state.level} initSubmission={state.submission}/> }
                { section === 'survey' && <Survey type={state.type} initSurveyStatus={state.survey}
                                                    setSection={setSection} /> }
                { section === 'finished' && <Finished /> }

        </>
        // </Grid>
        // </Box>

        // <Box display="flex"
        //         justifyContent="center"
        //         alignItems="center"
        //         minHeight="100svh"
        //         border="0.2em"
        //         paddingLeft="1em"
        //         paddingRight="1em"
        //         sx={{ flexGrow: 1 }}>
        //     <Grid container xs={12} sm={9} md={6} lg={4} p={4} 
        //         justifyContent="center" 
        //         spacing="0.5em" 
        //         border="0.2em"
        //         paddingLeft="0em"
        //         paddingRight="0em"
        //         direction="column">
        //         { section === "intro" && <Intro initIntroState={state.intro} setSection={setSection} /> }
        //         { section === "questions" && <Questions type={state.type} initQuestionStatus={state.questions} setSection={setSection}
        //                                                 initLevel={state.level} initSubmission={state.submission}/> }
        //         { section === 'survey' && <Survey type={state.type} initSurveyStatus={state.survey}
        //                                         setSection={setSection} /> }
        //         { section === 'finished' && <Finished /> }
        //     </Grid>
        // </Box>
    );
}