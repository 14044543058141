import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Progress from "../components/Progress";
import LevelView from "./LevelView";

export default function LevelRankView({
    // tutorial
    enable_tutorial_button = false, 
    enable_tutorial_overlay = false, 
    tutorial_overlay_content = null,
    onOverlayOk = () => {},
    enable_tutorial_finish = false,
    onFinishTutorial = () => {},

    // info
    enable_info = true,
    highlighted_info = false,
    onInfoState = () => {},
    autoopen_info = false,
    allow_info = true,

    // survey
    enable_survey = false,
    highlight_survey = false,
    onSurvey = () => {},
    allow_survey = true,

    // submit
    enable_submit = true,
    highlighted_submit = false,
    
    onSubmit = _ => {},
    allow_submit = null,

    // data
    submit_text, enable_rank, data, description, progress,

    default_state = 0,
}) {

    return (
        <LevelView
                key={tutorial_overlay_content}
                enable_encoding={false}

                enable_tutorial_finish={enable_tutorial_finish}
                enable_tutorial_button={enable_tutorial_button}
                enable_tutorial_overlay={enable_tutorial_overlay}
                tutorial_overlay_content={tutorial_overlay_content}
                onOverlayOk={onOverlayOk}

                enable_info={enable_info}
                highlighted_info={highlighted_info}
                onInfoState={onInfoState}

                enable_survey={enable_survey}
                highlight_survey={highlight_survey}
                onSurvey={onSurvey}
                allow_survey={allow_survey}

                enable_submit={enable_submit}
                highlighted_submit={highlighted_submit}
                enable_report={false}
                allow_submit={allow_submit}
                submit_text={submit_text}

                allow_info={allow_info}

                autoopen_info={autoopen_info}
                default_state={default_state}
                onFinishTutorial={onFinishTutorial}
                
                onSubmit={onSubmit}>
            
            <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    marginBottom="0.5em">

                <Grid item>
                    <Typography variant="h2" className="mainTitle">
                        Progresso
                    </Typography>
                </Grid>

                <Grid item>
                    {description}
                </Grid>

                { enable_rank &&
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">pos</TableCell>
                                        <TableCell align="center">utente</TableCell>
                                        <TableCell align="center">pti</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map(e => (
                                            e[1].includes("YOU ")
                                            ?
                                            <TableRow>
                                                <TableCell align="center">
                                                    <strong>{e[0]}°</strong>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <strong>{ ">> " + e[1].replace("YOU ", "") + " <<" }</strong>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <strong>{ e[2] }</strong>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <TableRow align="center">
                                                <TableCell align="center">
                                                    {e[0]}°
                                                </TableCell>
                                                <TableCell align="center">
                                                    { e[1].replace("YOU ", "") }
                                                </TableCell>
                                                <TableCell align="center">
                                                    { e[2] }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }

                <Grid item>
                    <Progress done={progress} />
                </Grid>
            </Grid>
        </LevelView>
    )
}